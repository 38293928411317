import React from "react"
import { graphql } from "gatsby"
import urlBuilder from "@sanity/image-url"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import { sanityConfig } from "../sanityConfig"
import ButtonLink from "../components/ButtonLink"
import HeroContainer from "../components/heroContainer"
import { AiOutlineLeft } from "react-icons/ai"
import PageTitle from "../components/PageTitle"
import StandardContainer from "../components/containers/StandardContainer"

// TO DO: Add rich text content!

const urlFor = (source) => urlBuilder(sanityConfig).image(source)

const serializer = {
  types: {
    inlineImage: (props) => (
      <img
        className="settings__inlineImage"
        alt={props.node.alt}
        src={urlFor(props.node.asset).url()}
        style={{ maxHeight: "250px", objectFit: "cover", margin: "20px 0" }}
      />
    ),
  },
}

const BlogPostTemplate = ({ data }) => {
  const { sanityBlogPost, defaultImage } = data
  const createdAtDate = sanityBlogPost._createdAt
  const rawPostContent = sanityBlogPost._rawPostContent
  const publishedDate = sanityBlogPost.publishedDate || null

  // only assign if the data exists
  const featureImage =
    sanityBlogPost?.featureImage?.asset?.fluid ||
    defaultImage?.childImageSharp?.fluid

  const heroProps = {
    fluid: featureImage,
    gradient: true,
  }

  return (
    <Layout>
      <SEO title={`${sanityBlogPost.title}`} />

      <HeroContainer props={heroProps} className=" " />
      <StandardContainer className="px-5 md:px-10 py-10  relative text-primary ">
        <div className="grid  justify-items-start">
          <ButtonLink
            to="/news"
            className="flex items-center gap-x-2 uppercase tracking-wide my-5 "
          >
            <AiOutlineLeft /> All News
          </ButtonLink>
        </div>
        <div className="grid">
          <div id="top-matter" className="grid">
            <PageTitle text={sanityBlogPost.title} className="mb-3" />

            <div className=" text-center text-xl ">
              <span className="font-bold">Date:</span>{" "}
              {publishedDate || createdAtDate}
            </div>
            {sanityBlogPost.author && (
              <div className="text-center text-xl">
                <span className="font-bold">Author:</span>{" "}
                {sanityBlogPost.author}
              </div>
            )}
          </div>

          <div className="">
            <BlockContentPrimary blockData={rawPostContent} />
          </div>
        </div>
      </StandardContainer>
    </Layout>
  )
}

// "$slug: String!" means that we are pulling in the slug from the context (passed in BlogPostTemplate) and that it must be a string value
// "(slug: { current: { eq: $slug } })" means that the slug from the query needs to match the value from the context, so that we get info for the correct page.
//see video 16 of LUT gatsby/shopify

export const query = graphql`
  query sanityBlogPostQuery($slug: String!) {
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      id
      _createdAt(formatString: "MMMM Do, YYYY")
      publishedDate(formatString: "MMMM Do, YYYY")
      title
      author
      summary
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      featureImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    defaultImage: file(relativePath: { eq: "SLKT Train Station-Banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default BlogPostTemplate
